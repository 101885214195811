<template>
  <!-- 录入学徒 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)" />

    <div class="main-content">
      <div class="body">
        <a-steps :current="stepsCurrent">
          <a-step
            v-for="item in steps"
            :key="item.title"
            :title="item.title"
            :description="item.description"
          />
        </a-steps>

        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-form-model-item
            label="学徒照片（用作头像）"
            prop="avatar"
            v-if="stepsCurrent === 0"
          >
            <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="uploadUrl"
              :data="{
                token: qiniuData.token
              }"
              :before-upload="beforeAvatarUpload"
              @change="handleAvatarChange"
            >
              <img
                class="avatar"
                :src="form.avatar"
                alt="avatar"
                v-if="form.avatar"
              />
              <div v-else>
                <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item
            ref="realName"
            label="学徒姓名："
            prop="realName"
            v-if="stepsCurrent === 0"
          >
            <a-input
              v-model="form.realName"
              placeholder="请输入学徒真实姓名"
              @blur="
                () => {
                  $refs.realName.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            ref="studentNumber"
            label="学徒学号"
            prop="studentNumber"
            v-if="stepsCurrent === 0"
          >
            <a-input
              v-model="form.studentNumber"
              placeholder="请输入学徒学号"
              @blur="
                () => {
                  $refs.studentNumber.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            ref="mobile"
            label="联系方式"
            prop="mobile"
            v-if="stepsCurrent === 0"
          >
            <a-input
              v-model="form.mobile"
              placeholder="请输入微信绑定的手机号"
              @blur="
                () => {
                  $refs.mobile.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            label="常住地址"
            prop="address"
            v-if="stepsCurrent === 0"
          >
            <a-select
              allow-clear
              show-search
              placeholder="请输入常驻地址"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              @search="handleAddressChange"
              @change="handleAddressChange"
              :not-found-content="null"
              v-model="form.address"
            >
              <a-select-option
                v-for="(d, index) in addressReturn"
                :key="d.value + index"
              >
                {{ d.value }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <!-- 选择门店 -->
          <a-form-model-item
            ref="servicerType"
            label="师傅归属类型"
            prop="servicerType"
            v-if="list.userType !== 'community'"
          >
            <a-select
              class="select"
              :default-value="0"
              placeholder="请选择师傅归属类型"
              v-model="form.servicerType"
              @change="handleSelectChange"
            >
              <a-select-option
                v-for="(item, index) in PointsList"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 选择渠道经理点 -->
          <a-form-model-item
            ref="communityId"
            label="渠道经理"
            prop="communityId"
            v-if="form.servicerType === 3"
          >
            <a-select
              class="select"
              :default-value="0"
              placeholder="请选择渠道经理"
              v-model="form.communityId"
              @change="handleSelectChange"
            >
              <a-select-option
                v-for="(item, index) in comminity"
                :key="index"
                :value="item.communityId"
              >
                {{ item.storename }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 选择运营点 -->

          <template v-if="stepsCurrent === 1">
            <a-form-model-item
              ref="IDcardNum"
              label="身份证号码"
              prop="IDcardNum"
            >
              <a-input
                v-model="form.IDcardNum"
                placeholder="请输入18位身份证号码"
                @blur="
                  () => {
                    $refs.IDcardNum.onFieldBlur()
                  }
                "
              />
            </a-form-model-item>
            <div class="ID-card">
              <a-form-model-item
                prop="IDcardBackUrl"
                label="身份证图像"
                :label-col="{ span: 9 }"
                :wrapper-col="{ span: 14 }"
              >
                <a-upload
                  name="file"
                  list-type="picture-card"
                  class="IDcard-uploader"
                  :show-upload-list="false"
                  :action="uploadUrl"
                  :before-upload="beforeIdCardUpdate"
                  :data="{
                    token: qiniuData.token
                  }"
                  v-model="form.IDcardBackUrl"
                  @change="handleIDcardBackChange"
                >
                  <div v-if="form.IDcardBackUrl">
                    <img class="img" :src="form.IDcardBackUrl" />
                  </div>
                  <div v-else>
                    <a-icon :type="IDcardBackLoading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">上传身份证人像面</div>
                  </div>
                </a-upload>
              </a-form-model-item>
              <a-form-model-item
                prop="IDcardFrontUrl"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-upload
                  name="file"
                  list-type="picture-card"
                  class="IDcard-uploader"
                  :show-upload-list="false"
                  :action="uploadUrl"
                  :before-upload="beforeIdCardUpdate"
                  :data="{
                    token: qiniuData.token
                  }"
                  v-model="form.IDcardFrontUrl"
                  @change="handleIDcardFrontChange"
                >
                  <div v-if="form.IDcardFrontUrl">
                    <img class="img" :src="form.IDcardFrontUrl" />
                  </div>
                  <div v-else>
                    <a-icon :type="IDcardFrontLoading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">上传身份证国徽面</div>
                  </div>
                </a-upload>
              </a-form-model-item>
            </div>
            <div class="ID-time">
              <div class="ID-time-label">身份证有效期</div>
              <a-form-model-item
                label=""
                prop="IDcardStartTime"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-date-picker
                  v-model="form.IDcardStartTime"
                  placeholder="开始日期"
                  @change="onStartTimeChange"
                />
              </a-form-model-item>
              <a-form-model-item
                class="date-picker-2"
                :prop="IDcardEndTimeProp"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-date-picker
                  v-model="form.IDcardEndTime"
                  placeholder="结束日期"
                  @change="onEndTimeChange"
                  :disabled="timeCheckboxValue"
                />
              </a-form-model-item>
              <a-form-model-item
                class="checkbox"
                prop="checkbox"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-checkbox
                  v-model="timeCheckboxValue"
                  @change="onTimeCheckChange"
                >
                  长期
                </a-checkbox>
              </a-form-model-item>
            </div>
          </template>

          <template v-if="stepsCurrent === 2">
            <a-form-model-item
              ref="trainNumber"
              label="培训合同编号"
              prop="trainNumber"
            >
              <a-input
                v-model="form.trainNumber"
                placeholder="请输入培训合同编号"
                @blur="
                  () => {
                    $refs.trainNumber.onFieldBlur()
                  }
                "
              />
            </a-form-model-item>

            <a-form-model-item
              prop="contract"
              label="合同扫描版（PDF）"
              extra="支持扩展名：.rar .zip .doc .docx .pdf .jpg..."
            >
              <a-upload
                name="file"
                :multiple="true"
                :action="uploadUrl"
                :data="{
                  token: qiniuData.token
                }"
                @change="handleContractChange"
              >
                <a-button> <a-icon type="upload" /> 上传合同 </a-button>
              </a-upload>
            </a-form-model-item>
          </template>

          <a-form-model-item class="submit-btn">
            <a-button type="primary" @click="onSubmit" v-if="stepsCurrent < 2">
              下一步
            </a-button>
            <a-button
              type="primary"
              @click="onSubmit"
              :loading="submitLoading"
              v-else
            >
              完成
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Debounce } from '../../utils/public'
let IDcardStartTime = ''
let IDcardEndTime = ''
export default {
  data() {
    return {
      title: '',
      stepsCurrent: 0,
      steps: [
        {
          title: '基础信息',
          description: '基础信息'
        },
        {
          title: '实名信息',
          description: '身份证号/图片实名信息'
        },
        {
          title: '上传合同协议',
          description: '上传实习学徒的合同'
        }
      ],
      form: {
        realName: '',
        avatar: '',
        mobile: '',
        IDcardNum: '',
        IDcardFrontUrl: '',
        IDcardBackUrl: '',
        address: undefined,
        studentNumber: '',
        trainNumber: '',
        contract: '',
        IDcardStartTime: undefined,
        IDcardEndTime: undefined,
        servicerType: undefined,
        communityId:undefined
      },
      IDcardEndTimeProp: 'IDcardEndTime',
      rules: {
        realName: [
          { required: true, message: '请输入实习真实姓名', trigger: 'blur' }
        ],
        servicerType: [
          { required: true, message: '请输入师傅类型', trigger: 'blur' }
        ],
         communityId: [
          { required: true, message: '请输入渠道经理点', trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: '请上传学徒照片', trigger: 'change' }
        ],
        mobile: [
          { required: true, message: '请输入微信绑定的手机号', trigger: 'blur' }
        ],
        studentNumber: [
          { required: true, message: '请输入实习学徒学号', trigger: 'blur' }
        ],
        IDcardNum: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' }
        ],
        IDcardFrontUrl: [
          { required: true, message: '请上传身份证国徽面', trigger: 'change' }
        ],
        IDcardBackUrl: [
          { required: true, message: '请上传身份证人像面', trigger: 'change' }
        ],
        IDcardStartTime: [
          { required: true, message: '请输入开始日期', trigger: 'change' }
        ],
        IDcardEndTime: [
          { required: true, message: '请输入结束日期', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入常驻地址', trigger: 'change' }
        ],
        contract: [
          { required: true, message: '请上传合同', trigger: 'change' }
        ],
        trainNumber: [
          { required: true, message: '请输入培训合同编号', trigger: 'blur' }
        ]
      },
      avatarLoading: false,
      addressReturn: [],
      IDcardFrontLoading: false,
      IDcardBackLoading: false,
      uploadUrl: process.env.VUE_APP_QINIUPHOST,
      submitLoading: false,
      timeCheckboxValue: false,
      PointsList: [
        {
          name: '平台师傅',
          id: 1
        },
        {
          name: '外请师傅',
          id: 2
        },
        {
          name: '渠道经理的师傅',
          id: 3
        }
      ],
      comminity: [],
      list:[]
    }
  },
  computed: {
    ...mapState(['qiniuData'])
  },
  created() {
    this.title = this.$getPageTitle()
    this.list = JSON.parse(window.sessionStorage.getItem('list'))
    console.log("qiniuData",this.qiniuData.host)
  },
  methods: {
    handleAddressChange: Debounce(function (e) {
      // 地址搜索
      if (!e) return
      const baseUrl = `https://apis.map.qq.com/ws/place/v1/search?keyword=${e}&key=${process.env.VUE_APP_QQMAPKEY}&output=jsonp&boundary=region(中国)`
      this.$jsonp(baseUrl)
        .then((res) => {
          // console.log(res)
          const data = res.data.map((v) => {
            if (v.title) {
              return { value: `${v.title}` }
            }
          })
          this.addressReturn = data
        })
        .catch((err) => {
          console.log(err)
        })
    }, 500),
    handleAvatarChange(info) {
      // 头像上传
      if (info.file.status === 'uploading') {
        if (this.form.avatar) {
          this.form.avatar = ''
        }
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.form.avatar = this.qiniuData.host + info.file.response.key
        this.avatarLoading = false
      }
    },
    handleIDcardFrontChange(info) {
      // 身份证正面上传
      if (info.file.status === 'uploading') {
        if (this.form.IDcardFrontUrl) {
          this.form.IDcardFrontUrl = ''
        }
        this.IDcardFrontLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.form.IDcardFrontUrl = this.qiniuData.host + info.file.response.key
        this.IDcardFrontLoading = false
      }
    },
    handleIDcardBackChange(info) {
      // 身份证反面上传
      if (info.file.status === 'uploading') {
        if (this.form.IDcardBackUrl) {
          this.form.IDcardBackUrl = ''
        }
        this.IDcardBackLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.form.IDcardBackUrl = this.qiniuData.host + info.file.response.key
        this.IDcardBackLoading = false
      }
    },
    async beforeAvatarUpload(file) {
      // 头像上传检验
      await this.$examineImgWH(file)
    },
    async beforeIdCardUpdate(file) {
      // 身份证上传检验
      await this.$examineImgDirection(file, 'horizontal')
    },
    onTimeCheckChange(e) {
      // 监听身份证有效期切换
      if (e.target.checked) {
        this.IDcardEndTimeProp = ''
        this.$refs.ruleForm.clearValidate('IDcardEndTime') // 移除表单项的校验结果
      } else {
        this.IDcardEndTimeProp = 'IDcardEndTime'
      }
    },
    onStartTimeChange(date, dateString) {
      // 身份证有效期开始日期
      IDcardStartTime = dateString
    },
    onEndTimeChange(date, dateString) {
      // 身份证有效期结束日期
      IDcardEndTime = dateString
    },
    handleContractChange(info) {
      console.log("info",info)
      // 合同上传
      if (info.file.status === 'done') {
        this.form.contract = this.qiniuData.host + info.file.response.key
      }
    },
    handleSelectChange(e) {
      console.log(e)
      if (e === 3) {
        this.getCommunity()
      }
    },
    getCommunity() {
      this.$axios.getCommunity().then((res) => {
        this.comminity = res.data.data.list
        console.log('d', this.comminity)
      })
    },
    onSubmit() {
      console.log("this.form",this.form)
      // 下一步/提交
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.stepsCurrent === 1) {
            delete this.form.IDcardStartTime
            if (this.timeCheckboxValue) {
              this.form.IDcardEndTime = '长期'
            } else {
              this.form.IDcardEndTime = `${IDcardStartTime} - ${IDcardEndTime}`
            }
          }
          if (this.stepsCurrent === 2) {
            this.submitLoading = true
            this.form.address = this.form.address.substring(
              0,
              this.form.address.length - 1
            )
            this.$axios
              .addApprentice(this.form)
              .then(() => {
                this.submitLoading = false
                this.$message.success('操作成功')
                this.$store.commit('changeRefreshApprentice', Date.now())
                this.$router.go(-1)
              })
              .catch(() => {
                this.submitLoading = false
              })
          } else {
            this.stepsCurrent++
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.body {
  background-color: #fff;
  padding: 48px 0;
}

.ant-steps,
.ant-form {
  width: 755px;
  margin: 0 auto;
}

.ant-form {
  margin-top: 40px;
}

.submit-btn {
  margin-left: 18em;
}

.avatar {
  display: block;
  width: 88px;
  height: 88px;
}

.ID-card,
.ID-time {
  display: flex;
}

.ID-time-label {
  color: rgba(0, 0, 0, 0.85);
  margin-right: 4px;
}

.ID-time-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ID-time-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.ID-card {
  padding-left: 6.5em;
}

.ID-card >>> .IDcard-uploader .ant-upload.ant-upload-select-picture-card,
.ID-time >>> .ant-calendar-picker {
  width: 160px;
}

.ID-time {
  padding-left: 5.5em;
}

.ID-time >>> .date-picker-2 {
  margin-left: 8px;
}

.ID-time >>> .checkbox {
  margin-left: 16px;
}
</style>
<style>
.body
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item-description {
  max-width: 200px;
}
</style>
